import React, { Fragment } from 'react';
import { Spinner } from 'reactstrap';

const CustomSpinner = () => {
  return (
    <Fragment>
      <div className='d-flex justify-content-center'>
        <Spinner style={{ width: '7.5rem', height: '7.5rem' }} color='info' />
      </div>
    </Fragment>
  );
}

export default CustomSpinner;