import React, { lazy, Fragment, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CustomSpinner from './components/reusable/CustomSpinner';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import './styles/styles.scss';

// Containers
const Layout = lazy(() => import('./components/Layout'));

// Pages
const Login = lazy(() => import('./containers/Login'));

const App = () => {
  return (
    <Fragment>
      <Router>
        <Suspense fallback={<CustomSpinner />}>
          <Switch>
            <Route exact path='/login' name='Login Page' render={props => <Login {...props} />} />
            <ProtectedRoute path='/' name='Home' render={props => <Layout {...props} />} />
          </Switch>
        </Suspense>
      </Router>
    </Fragment>
  );
}

export default App;
