import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

const ProtectedRoute = props => {
  const [isAuthenticated] = useState(localStorage.getItem('authenticated'));

  return (
    <>
      {
        isAuthenticated === "true" ?
          <props.render />
          :
          <Redirect to={{ pathname: '/login' }} />
      }
    </>
  );
}

export default ProtectedRoute;